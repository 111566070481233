<header>
    <div class="header">
    <div class="logo" (click)="navigatetoHomepage()">
        <img src="../assets/icons/Clicko Logo.svg" alt=""> 
    </div>
    <div class="partner" *ngIf="isPartnerVisible" (click)="navigatetoPartner()">
        <p>
            Clicko partner
        </p>
    </div>
    <div class="dropdown menu" >
        <button  class="dropbtn" (click)="toggleDropdown('dropdown1')" >
            <p>Meni </p>
           <img src="../assets/icons/expand_more.png" alt="" class="arrow" >
        </button>
        <div *ngIf="isDropdownOpen1" id="myDropdown1" class="dropdown-content">
        <div class="flex">
            <img src="../assets/icons/Clicko Logo.svg" alt="" (click)="navigatetoHomepage()">
            <span class="material-symbols-outlined" (click)="closeMenu()">
                    close
            </span>
        </div>
        <div class="box" (click)="navigatetoLogin()" *ngIf="!isUserLoggedIn()">
            <p>Prijavi se</p>
        </div>
        <div class="box" (click)="navigatetoSignup()" *ngIf="!isUserLoggedIn()">
            <p>Kreiraj nalog</p>
        </div>
        <div class="box" (click)="navigatetoPage()" *ngIf="isUserLoggedIn()">
            <p>Profil</p>
        </div>
        <div class="box green" (click)="logout()" *ngIf="isUserLoggedIn()">
            <p>Odjavi me</p>
        </div>
        <div class="box green" (click)="navigatetoPartner()" *ngIf="!isUserLoggedIn()">
            <p>Clicko Partner</p>
        </div>
        </div>
    </div>
  </div>
</header>