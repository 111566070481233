import { Component, ElementRef, OnInit, Renderer2, ViewChild, inject,  HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, startWith, of } from 'rxjs';
import ValidateForm from 'src/app/helpers/validateform';
import { AppointmentDTO } from 'src/app/models/AppointmentDTO';
import { HomeDTO } from 'src/app/models/homepage';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HomepageService } from 'src/app/services/homepage/homepage.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.css']
})
export class PlaceComponent implements OnInit {

 
  isDropdownOpen: boolean = false;
  placeholderText: string = 'Salon ili usluga';

  placeCount: number = 0;
  places: any[] = [];
  place: any;

  appointments: any[] = [];
  appointment: any;
  photos: any[] = [];

  homeDTO: HomeDTO;
  cityList: any[] = [];
  categoriesList: any[] = [];

  searchData: any;



filteredCategories$: Observable<any[]>;
searchForm: FormGroup;
isCategoryListVisible = false;
hovered: boolean = false;
isCityListVisible = false;
popularCategory: boolean = false;
mapExpand: boolean = false;



  constructor( 
    private salonService: SalonService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private homepageService: HomepageService
    ) { 

      this.searchForm = this.fb.group({
        category: new FormControl(''),
        date: new FormControl(''),
      });
    }

  
  ngOnInit(): void {

    this.searchData = this.salonService.getData();

  
    if (!this.searchData.city && !this.searchData.category && !this.searchData.date) {
      this.salonService.getPlaces().subscribe((places) => {
          this.placeCount = places.length;
          this.places = places;
      });
  } 
    else if (this.searchData.city === null && this.searchData.category === '') {
      this.salonService.getSearchedPlacesbyCity(this.searchData.city, this.getFormattedDateForApi(this.searchData.date)).subscribe((places) => {
        this.placeCount = places.length;
        this.places = places;
      });
    } else if (this.searchData.city === null) {
      this.salonService.getSearchedPlaces(this.searchData.category, this.searchData.city, this.getFormattedDateForApi(this.searchData.date)).subscribe((places) => {
        this.placeCount = places.length;
        this.places = places;
      });
    } else if (this.searchData.category === '') {
      this.salonService.getSearchedPlacesbyCity(this.searchData.city, this.getFormattedDateForApi(this.searchData.date)).subscribe((places) => {
        this.placeCount = places.length;
        this.places = places;
      });
    }
    

    this.searchForm.patchValue({
      category: this.transformWordPatch(this.searchData.category),
      date: this.searchData.date,
    });


    this.homepageService.homepageApi().subscribe({
      next: (data) => {
        this.homeDTO = data;
        this.cityList = data.city;
        this.categoriesList = data.categories;
        // console.log(this.homeDTO);
        // console.log(this.categoriesList)
      },
      error: (error) => {
        console.error(error);
      }
    });
  
 
    this.filteredCategories$ = this.searchForm.get('category').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCategories(value))
    );
  

  
    this.searchForm.get('category').valueChanges.subscribe(value => {
      this.isCategoryListVisible = !!value;
    });

    this.searchForm.get('category').valueChanges.subscribe((value) => {
      const transformed = this.transformWord(value || 'Salon ili usluga');
      this.placeholderText = transformed || 'Salon ili usluga';
    });
  }
  

  
  private filterCategories(value: string): string[] {
    const filterValues = value.toLowerCase().split(' ').filter(Boolean); 
    return this.categoriesList.filter(category => {
      const categoryName = category.toLowerCase().replace(/[čćžšđ]/g, (match) => {
        switch(match) {
          case 'č': return 'c';
          case 'ć': return 'c';
          case 'ž': return 'z';
          case 'š': return 's';
          case 'đ': return 'd';
          default: return match; 
        }
      });
      return filterValues.every(word => categoryName.includes(word));
    });
  }
  

  
  selectCategory(category: string): void {
    const transformedCategory = this.transformWord(category);
    this.searchForm.get('category').setValue(transformedCategory);
    this.isCategoryListVisible = false;
  }

  showPopularCategories(event: MouseEvent): void {
    this.popularCategory = true;
    event.stopPropagation(); 
  }

  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    this.popularCategory = false;
  }

  
  
  onSearchSubmit(): void {

    const transformedCategory = this.transformBack(this.searchForm.get('category').value);
    const transformedDate = this.searchForm.get('date').value;

    const categoryValue = transformedCategory !== '' ? transformedCategory : null;
    const dateValue = transformedDate !== '' && transformedDate !== null ? transformedDate : null;

    console.log(categoryValue);
    console.log(dateValue);

    this.searchForm.setValue({
      category: categoryValue,
      date: dateValue,
    });

      console.log(this.searchForm.value);
    
      this.salonService.setData(categoryValue, this.searchData.city, dateValue);

      if (!this.searchData.city && !categoryValue && !dateValue) {
       
        this.salonService.getPlaces().subscribe((places) => {
            this.placeCount = places.length;
            this.places = places;
        });
      }
      else if (this.searchData.city === null && categoryValue === null) {
        this.salonService.getSearchedPlacesbyCity(this.searchData.city, this.getFormattedDateForApi(dateValue)).subscribe((places) => {
          this.placeCount = places.length;
          this.places = places;
        });
      } else if (this.searchData.city === null) {
        this.salonService.getSearchedPlaces(categoryValue, this.searchData.city, this.getFormattedDateForApi(dateValue)).subscribe((places) => {
          this.placeCount = places.length;
          this.places = places;
        });
      } else if (categoryValue === null) {
        this.salonService.getSearchedPlacesbyCity(transformedCategory, this.getFormattedDateForApi(this.searchData.date)).subscribe((places) => {
          this.placeCount = places.length;
          this.places = places;
        });
      }
      
      
      this.placeholderText = this.transformWord(categoryValue || 'Salon ili usluga');
      this.isCategoryListVisible = false;
  
      
  
  }

  getAllSalons(): void {
    this.salonService.getPlaces().subscribe((item) => {
    
    });
  }

  transformWordPatch(word: string): string {
    console.log('Original word:', word);
    if (word) {
      const transformed = word.toLowerCase().split(' ').map(word => { 
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ').replace(/_/g, ' ');
      console.log('Transformed word:', transformed);
      return transformed;
    } else {
      return '';
    }
  }


  transformWord(word: string): string {
    if (word) {
    return word.toLowerCase().split(' ').map(word => { 
    return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
     } else {
    return '';
    }
  }
    
  transformBack(word: string): string {
      return word.toUpperCase().replace(/ /g, '_');
  }
  
  getFormattedDateForApi(date: Date): string {
    if (date) {
      const year = date.getFullYear();
      const month = this.padZero(date.getMonth() + 1);
      const day = this.padZero(date.getDate());
      return `${year}-${month}-${day}`;
    } else {
      return '';
    }
  }
  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  addHoverEffect() {
    const spanElement = this.elementRef.nativeElement.querySelector('.mat-icon span');
    this.renderer.addClass(spanElement, 'hover-effect');
  }


  @ViewChild('listDiv', { static: false }) listDiv!: ElementRef;
  @ViewChild('mapDiv', { static: false }) mapDiv!: ElementRef;
  @ViewChild('height', { static: false }) height!: ElementRef;
  @ViewChild('expand', { static: false }) expand!: ElementRef;
  @ViewChild('collapse', { static: false }) collapse!: ElementRef;
  @ViewChild('header', { static: false }) header!: ElementRef;
 

  ngAfterViewInit() {
    
  }

  

  expandContent() {

    // if (this.listDiv) {
  
    //   this.listDiv.nativeElement.style.display = 'none';
    // }
    // if (this.mapDiv) {
  

    // this.mapDiv.nativeElement.style.height = '100%';
    // this.mapDiv.nativeElement.style.width = '100%';
    // this.height.nativeElement.style.height = '100%';
    // this.height.nativeElement.style.width = '100%';
    // this.expand.nativeElement.style.display = 'none';
    // this.header.nativeElement.style.display = 'none';
    // this.collapse.nativeElement.style.display = 'block';
    // }
    this.mapExpand = true;
    console.log(this.mapExpand)
  }

  collapseContent() {
  //   console.log('Collapse content function called');
  //   if (this.listDiv) {
      
  //     this.listDiv.nativeElement.style.display = 'block'; 
  //   }
  //   if (this.mapDiv) {

  //   this.mapDiv.nativeElement.style.height = '100%';
  //   this.mapDiv.nativeElement.style.width = '100%';
  //   this.height.nativeElement.style.height = '268px';
  //   this.height.nativeElement.style.width = '100%';
  //   this.expand.nativeElement.style.display = 'block';
  //   this.header.nativeElement.style.display = 'block';
  //   this.collapse.nativeElement.style.display = 'none';
  // }
  this.mapExpand = false;

}

  navigatetoSalon(salonId: number): void {
    this.router.navigate(['salon', 'salon-services', salonId]);
  }


  navigateBack(){
    this.router.navigate(['homepage']);
  }

  navigateToSearch(){
    this.router.navigate(['place-search']);
  }





}
